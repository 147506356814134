.d-flex-resize {
  @media screen and (min-width: 950px) {
    display: flex;
  }
}

.RankingsEntry-Container {
  border-radius: 8px;
  padding: 0.5rem;
  height: 100px;
  display: grid;
  grid-template-columns: 10% 90%; /* Adjust the column widths */
  align-items: center;
}

.RankingsEntry-Container:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.RankingsEntry-Info {
  padding-left: 10px;
  text-align: left; /* Add this line to align text to the left */
}
